<template>
  <el-cascader ref="area" :options="proList" @change="areaChange" v-model="dataValue"
   :props="{checkStrictly:checkStrictly,lazy:true,lazyLoad:lazyLoad,value:'id',label:'name' }" clearable ></el-cascader>
</template> 

<script>
import { getRegionListByParentId } from '@/api/index.js'
export default {
  name: 'ChooseArea',
  props: {
    value: String | Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    selectedLevel: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      proList:[],
      dataValue:'',
      //value: ['120000', '120100', '120102', '120102003'],
      //value: []
    }
  },
  watch: {
  },
  created() {
    this.dataValue = this.value
  },
  mounted(){
  },
  methods: {
    areaChange(value) {
      let selectedData = {proId:'',proName:'',cityId:'',cityName:'',regId:'',regName:'',streetId:'',streetName:''}
      let checkNodes = this.$refs['area'].getCheckedNodes();
      if(checkNodes.length==0){
        this.$emit('changeArea', selectedData)
        return;
      }

      let data = checkNodes[0];
      let idArr = data.path;
      let nameArr = data.pathLabels;
      for(var index=0;index<idArr.length;index++){
        switch(index){
          case 0://省
            selectedData.proId = idArr[index];
            selectedData.proName = nameArr[index];
            break;
          case 1://市 
            selectedData.cityId = idArr[index];
            selectedData.cityName = nameArr[index];
            break;
          case 2://区
            selectedData.regId = idArr[index];
            selectedData.regName = nameArr[index];
            break;
          case 3://街道
            selectedData.streetId = idArr[index];
            selectedData.streetName = nameArr[index];
            break;
        }

      }
      selectedData.selectedId = data.value;
      selectedData.selectedName = data.label;
      this.$emit('changeArea', selectedData)
    },

    loadProList(){
      let form = {parentId:''};
      getRegionListByParentId(form).then(res => {
        if (res.code != 200) return
        this.proList = res.data        
      })
    },
    lazyLoad(node, resolve){
      let parentId = ''; 
      if(node.level!=0){
        parentId=node.data.id;
      }
      let form = {parentId: parentId};
      getRegionListByParentId(form).then(res => {
        if (res.code != 200) return     
        if(node.level == this.selectedLevel-1){
          let data = res.data.map(item => ({id:item.id,name:item.name,leaf:true}))   
            resolve(data)
          }else{
            resolve(res.data)
        }
      })

    }
  }
}
</script>

<style>
</style>