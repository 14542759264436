<template>
  <div style="width:100%">
      
    <TeacherOrderList v-if="editType == 'list'" @changeShowType="changeShowType"></TeacherOrderList>

    <TeacherOrderInfo v-if="editType != 'list'" :editId="editId"  :editType="editType" @changeShowType="changeShowType"></TeacherOrderInfo>
      
  </div>
</template>

<script>
import TeacherOrderList from './components/TeacherOrderList'
import TeacherOrderInfo from '../components/TeacherOrderInfo'
import {  } from '@/api/index.js'
export default {
  name: 'teacherOrder',
  components: {  TeacherOrderList,TeacherOrderInfo},
  data() {
    return {
      editType:'list',
      editId:'',
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,id){
      if(type=='edit'){
        this.editId = id;
      }
      this.editType = type;
    }
  }
}
</script>

<style>
</style>