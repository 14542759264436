<template>
  <el-select v-model="selectedVal" placeholder="请选择" style="width:120px" @change="change" filterable  >
    <el-option label="全部" value="" v-if="showAll"></el-option>
    <el-option label="待支付" value="0"></el-option>
    <el-option label="支付中" value="1"></el-option>
    <el-option label="已支付" value="2"></el-option>
    <el-option label="全额退款" value="3"></el-option>
    <el-option label="部分退款" value="4"></el-option>
    <el-option label="已关闭" value="5"></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'ChooseOrderState',
  props: {
    value: Number | String,
    showAll: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      selectedVal:''
    }
  },
  created() {
  },
  watch: {
  },
  methods: {
    change() {
      this.$emit('input', this.selectedVal)
    }
  }
}
</script>

<style>
</style>